<template>
  <div>
    <loading :active.sync="is_call_api"></loading>
    <v-row justify="center">
      <v-dialog
        v-model="dialogGradingScaleSkill"
        max-width="10000px"
        scrollable
        persistent
      >
        <v-card>
          <v-card-title>
            <v-col cols="11">
              <span class="headline">Manage Skill Scores Mapping</span>
            </v-col>
            <v-col cols="1">
              <v-icon @click="dialogGradingScaleSkill = false" right
                >mdi-close</v-icon
              >
            </v-col>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="1"></v-col>
                    <v-col cols="2" class="d-flex">
                      <label style="font-size: 16px" class="p-0 mt-4"
                        >From:
                      </label>
                      <v-text-field
                        v-model="from_input"
                        type="number"
                        style="max-width: 150px"
                        class="ml-4"
                        outlined
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2" class="d-flex">
                      <label style="font-size: 16px" class="p-0 mt-4"
                        >To:
                      </label>
                      <v-text-field
                        v-model="to_input"
                        type="number"
                        style="max-width: 150px"
                        class="ml-4"
                        outlined
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2" class="d-flex">
                      <label style="font-size: 16px" class="p-0 mt-4"
                        >Score:
                      </label>
                      <v-text-field
                        v-model="score_input"
                        type="number"
                        style="max-width: 150px"
                        class="ml-4"
                        outlined
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-btn
                        color="blue darken-1"
                        outlined
                        rounded
                        class="mt-2"
                        @click="btnAddDetaill"
                      >
                        <v-icon color="">mdi-plus</v-icon>
                        Add Detail
                      </v-btn>
                    </v-col>
                    <v-col cols="1"></v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <div class="table-responsive">
                    <table
                      class="table datatable-bordered table-head-custom table-vertical-center table-head-bg"
                    >
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">From Correct Answer</th>
                          <th scope="col">To Correct Answer</th>
                          <th scope="col">Score</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-for="(detail, i) in calculate_details">
                          <tr :key="i">
                            <td scope="row">{{ i + 1 }}</td>
                            <td>
                              <p
                                class="text-dark-75 font-weight-bolder d-block font-size-lg"
                              >
                                {{ detail.from_correct_answer }}
                              </p>
                            </td>
                            <td>
                              <p
                                class="text-dark-75 font-weight-bolder d-block font-size-lg"
                              >
                                {{ detail.to_correct_answer }}
                              </p>
                            </td>
                            <td>
                              <p
                                class="text-dark-75 font-weight-bolder d-block font-size-lg"
                              >
                                {{ detail.score }}
                              </p>
                            </td>
                            <td>
                              <div style="display: flex">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <button
                                      class="btn btn-icon btn-light-danger btn-sm ml-4"
                                      v-bind="attrs"
                                      v-on="on"
                                      @click="btnDeleteDetail(detail.id)"
                                    >
                                      <span
                                        class="svg-icon svg-icon-md svg-icon-primary"
                                      >
                                        <v-icon color="">mdi-delete</v-icon>
                                      </span>
                                    </button>
                                  </template>
                                  <span>Delete</span>
                                </v-tooltip>
                              </div>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <button
              class="btn text-uppercase mr-2"
              style="color: #f64e60"
              @click="dialogGradingScaleSkill = false"
            >
              Close
            </button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import "vue-loading-overlay/dist/vue-loading.css";
import ApiService from "@/service/api.service";
import Swal from "sweetalert2";
export default {
  name: "SkillCalculateDetail",
  components: {
    Loading: () => import("vue-loading-overlay"),
  },
  props: {
    show_dialog: {
      type: Boolean,
      default: false,
    },
    grading_scale_id: {
      type: String,
      default: null,
    },
    grading_scale_skill_id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      is_call_api: false,
      from_input: null,
      to_input: null,
      score_input: null,
      calculate_details: [],
    };
  },
  computed: {
    dialogGradingScaleSkill: {
      get() {
        return this.show_dialog;
      },
      set(val) {
        this.$emit("setDialog", val);
      },
    },
  },
  watch: {
    show_dialog(val) {
      if (val) {
        this.from_input = null;
        this.to_input = null;
        this.score_input = null;
        this.getScaleSkillDetail();
      }
    },
  },
  methods: {
    async getScaleSkillDetail() {
      if (this.grading_scale_id && this.grading_scale_skill_id) {
        let vm = this;
        try {
          vm.is_call_api = true;
          let res = await ApiService.get(
            "prep-app/grading-scale/" +
              this.grading_scale_id +
              "/skills/" +
              this.grading_scale_skill_id +
              "/details"
          );
          if (res.status == 200) {
            vm.calculate_details = res.data;
            vm.is_call_api = false;
          }
        } catch (error) {
          vm.is_call_api = false;
        }
      }
    },
    btnAddDetaill() {
      let vm = this;
      let data = {
        from_correct_answer: this.from_input,
        to_correct_answer: this.to_input,
        score: this.score_input,
      };
      let validate = this.validateForm(data);
      if (!validate) {
        return;
      }
      ApiService.post(
        "prep-app/grading-scale/" +
          this.grading_scale_id +
          "/skills/" +
          this.grading_scale_skill_id +
          "/details",
        data
      )
        .then(function (res) {
          if (res.status == 200) {
            vm.getScaleSkillDetail();
          }
        })
        .catch(function (error) {
          if (error.response.status == 422) {
            let data_response_error =
              error.response.data.error.message.split(".<br />");
            vm.showError(data_response_error);
          }
        });
    },
    showError(data_error) {
      for (let i = 0; i < data_error.length; i++) {
        this.$toasted.error(data_error[i] + " !!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
      }
    },
    validateForm(data) {
      let flat = true;
      if (data.from_correct_answer == null || data.from_correct_answer == "") {
        this.$toasted.error("Hãy nhập From Correct Answer!!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (data.to_correct_answer == null || data.to_correct_answer == "") {
        this.$toasted.error("Hãy nhập To Correct Answer!!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      if (data.score == null || data.score == "") {
        this.$toasted.error("Hãy nhập Score!!", {
          theme: "toasted-primary",
          position: "top-right",
          duration: 4000,
        });
        flat = false;
      }
      return flat;
    },
    btnDeleteDetail(detail_id) {
      let vm = this;
      let data = {
        detail_id: detail_id,
      };
      Swal.fire({
        title: "Are you sure you want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          ApiService.post(
            "prep-app/grading-scale/" +
              vm.grading_scale_id +
              "/skills/" +
              vm.grading_scale_skill_id +
              "/details/delete",
            data
          ).then(function (res) {
            if (res.status == 200) {
              vm.getScaleSkillDetail();
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="css" scoped>
.table.table-head-bg thead th {
  background-color: #2196f3eb !important;
  color: white !important;
}
</style>
